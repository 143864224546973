
  @import url('https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:wght@100&family=Outfit:wght@100;200;300;400;500;600;700;800;900&family=Poppins:wght@200;300;500;600&family=Sedgwick+Ave+Display&display=swap');
  
  @import url('https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:wght@100&family=Outfit:wght@100;200;300;400;500;600;700;800;900&family=Poppins:wght@200;300;500;600&family=Press+Start+2P&family=Sedgwick+Ave+Display&display=swap');

  *{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-family: 'Outfit', sans-serif;
  }

  body{
    background: black;
  }

  h1,h4,p, a{
        color: white;
        text-decoration: none;

  }

  ul{
    list-style: none;
  }


  .btn{
    padding: 12px 32px;
    font-size: 1rem;
    text-transform: uppercase;
    background: rgb(248, 217, 15);
    color: black;
    border: 1px solid white;
    font-weight: 600;
    cursor: pointer;
  }

  .btn-light{
    background: transparent;
    color: white;
  }

  .btn:hover{
    background: rgba(255, 255, 255, 0.2);
    color: white;
    transition: 0.3s;
  }