.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    position: fixed;
    width: 100%;
    height: 90px;
    z-index: 10;
  }
  .header-bg {
    background-color: rgba(0, 0, 0, 0.85);
    transition: 0.5s;
  }
  .nav-menu {
    display: flex;
  }
  
  .nav-menu li {
    padding: 0 1rem;
  }
  
  .nav-menu li a {
    font-size: 1.2rem;
    font-weight: 500;
    transition: border-bottom 0.3s ease;
  }
  /* .nav-menu li a:hover{
      border-bottom: 2px solid white;

    } */
   
    /* Add these styles to create the bottom border animation */
/* .nav-menu {
  list-style: none;
  padding: 0;
  display: flex;
  align-items: center;
}

.nav-menu li {
  margin: 0 15px;
  position: relative;
} */

.nav-menu a {
  text-decoration: none;
  color: white; /* Link color */
  position: relative;
  padding-bottom: 5px; /* Space for the border */
  transition: color 0.3s; /* Transition link color */
}

.nav-menu a::before {
  content: "";
  position: absolute;
  left: 0;
  right: 100%;
  bottom: 0;
  background-color: #fff; /* White border color */
  height: 2px; /* Border height */
  transform: scaleX(0);
  transition: transform 0.3s cubic-bezier(0.25, 1, 0.5, 1); /* Transition for the border */
}


.nav-menu a:hover::before {
  transform: scaleX(1);
  right: 0;
}


  
  
  




  .hamburger {
    display: none;
  }
  
  @media screen and (max-width: 1040px) {
    .nav-menu {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100vh;
      background: rgba(0, 0, 0, 0.9);
      position: absolute;
      top: 0;
      left: -100%;
      z-index: -3;
      transition: 0.3s;
    }
  
    .nav-menu.active {
      left: 0;
    }
  
    .nav-menu li {
      padding: 1rem 0;
    }
  
    .nav-menu li a {
      font-size: 2rem;
    }
  
    .hamburger {
      display: initial;
    }
  }
  