/* Skill.css */
.skill--item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid rgb(254, 110, 110);
  }
  
  .img {
    height: 5rem;
    width: 5rem;
    filter: invert(1);
  }
  
  .skill--card {
    margin: 50px 10%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 150px));
    gap: 1.5rem;
  }
  
  .skill-container {
    gap: 20px 50px;
    margin-top: 70px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
  
  .boxes {
    width: 100%;
    max-width: 550px;
    background: #1a1919;
    border-radius: 16px;
    padding: 18px 36px;
    margin: 13px 0;
    border: 2px solid white;
  }
  
  .boxes h2 {
    text-align: center;
    color: #b1b2b3;
  }
  
  .box1, .box2, .box3, .box4, .box5, .box6 {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
    color: #b1b2b3;
  }
  
  .box {
    border: 1px solid rgb(177, 178, 179);
    border-radius: 7px;
    padding: 5px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0px 10px;
  }
  
  .box img {
    height: 25px;
    width: 25px;
  }
  
  @media (max-width: 1600px) {
    .skill-container {
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }
  }
  
  @media (max-width: 1200px) {
    .skill-container {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
  }
  
  @media (max-width: 768px) {
    .skill-container {
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    }
  }
  
  @media (max-width: 480px) {
    .skill-container {
      grid-template-columns: 1fr;
    }
  }
  