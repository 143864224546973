.mask {
    width: 100%;
    height: 100vh;
    position: relative;
  }
  .into-img {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    object-fit: cover;
  }
  
  .mask::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #000;
    opacity: 0.8;
  }
  
  .hero {
    height: 100%;
    width: 100%;
  }
  
  .hero .content {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    text-align: center;
  }
  
  .hero .content h1 {
    font-size: 4rem;
    padding: 0.6rem 0 1.5rem;
  }
  .content h1{
    margin-top: 20px;
    font-family: 'Press Start 2P', cursive;

    overflow: hidden;
  border-right: .15em solid orange; 
    /* white-space: nowrap; Keeps the content on a single line */
  margin: 0 auto;
  letter-spacing: .15em; 

    animation: 
    typing 3.5s steps(40, end),
    blink-caret .75s step-end infinite;

  }
  @keyframes typing {
    from { width: 0 }
    to { width: 100% }
  }
  
 
  @keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: orange; }
  }
  
  .hero .content p {
    font-size: 1.4rem;
    font-weight: 200;
    text-transform: uppercase;
  }
  
  .content .btn {
    margin: 1rem 0.2rem;
  }
  
  @media screen and (max-width: 640px) {
    .hero .content h1 {
      font-size: 1.3rem;
   
    }
  
    .hero .content p {
      font-size: 1.4rem;
    }
  }
  